// src/App.js

import React from 'react';
import './App.css';
import ProductImageUpload from './ProductImageUpload';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Product Image Upload</h1>
      </header>
      <main>
        <ProductImageUpload />
      </main>
    </div>
  );
}

export default App;
