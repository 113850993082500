// src/ProductImageUpload.js

import React, { useState } from 'react';
import { CloudinaryContext, Image } from 'cloudinary-react';
import axios from 'axios';

const ProductImageUpload = () => {
  const [productID, setProductID] = useState('');
  const [mainImage, setMainImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [uploadedMainImage, setUploadedMainImage] = useState('');
  const [uploadedAdditionalImages, setUploadedAdditionalImages] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState('');

  const uploadImage = async (file, setUploadedUrl, folder) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'ajyaaugb'); // Replace with your actual unsigned upload preset name
    formData.append('folder', folder); // Set the folder path

    try {
      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/dzjykqcep/image/upload', // Replace with your cloud name
        formData
      );
      setUploadedUrl(response.data.secure_url);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMainImage(file);
      uploadImage(file, (url) => {
        setUploadedMainImage(url);
        setThumbnailImage(url); // You can also generate a specific thumbnail if needed
      }, `products/${productID}/main`);
    }
  };

  const handleAdditionalImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setAdditionalImages(files);
    files.forEach(file => {
      uploadImage(file, (url) => {
        setUploadedAdditionalImages(prevState => [...prevState, url]);
      }, `products/${productID}/additional`);
    });
  };

  const handleSaveToDatabase = async () => {
    console.log('Sending data:', {
      productID,
      mainImage: uploadedMainImage,
      additionalImages: uploadedAdditionalImages,
      thumbnailImage
    });

    try {
      const response = await axios.post('https://bfmbglobal.com/gallery/make_photos_upload.php', {
        productID,
        mainImage: uploadedMainImage,
        additionalImages: uploadedAdditionalImages,
        thumbnailImage,
      });

      if (response.data.status === 'success') {
        alert('Images saved to database successfully!');
      } else {
        alert(`Error: ${response.data.error}`);
      }
    } catch (error) {
      console.error('Error saving images to database:', error);
    }
  };

  return (
    <CloudinaryContext cloudName="dzjykqcep"> {/* Replace with your cloud name */}
      <div>
        <h2>Upload Product Images</h2>
        <div>
          <label>Product ID:</label>
          <input type="text" value={productID} onChange={(e) => setProductID(e.target.value)} />
        </div>
        <div>
          <label>Main Image:</label>
          <input type="file" onChange={handleMainImageChange} />
          {uploadedMainImage && <Image publicId={uploadedMainImage} width="300" crop="scale" />}
        </div>
        <div>
          <label>Additional Images:</label>
          <input type="file" multiple onChange={handleAdditionalImagesChange} />
          {uploadedAdditionalImages.map((url, index) => (
            <Image key={index} publicId={url} width="100" crop="scale" />
          ))}
        </div>
        <button onClick={handleSaveToDatabase}>Save to Database</button>
      </div>
    </CloudinaryContext>
  );
};

export default ProductImageUpload;
